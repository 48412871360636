import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import gql from 'graphql-tag'
import Application from 'lodestar-app/src/Application'
import LoadingPage from 'lodestar-app/src/pages/LoadingPage'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import './App.scss'
import hasura from './hasura'

const apolloClient = new ApolloClient({
  link: createHttpLink({ uri: `https://${process.env.REACT_APP_GRAPHQL_HOST}/v1/graphql` }),
  cache: new InMemoryCache(),
})

const App: React.VFC = () => {
  const host = window.location.host
  const [appOptions, setAppOptions] = useState<{
    appId?: string | null
    homeRedirect?: string | null
  }>({})

  useEffect(() => {
    apolloClient
      .query<hasura.GET_APP, hasura.GET_APPVariables>({
        query: GET_APP,
        variables: { host },
      })
      .then(({ data }) => {
        setAppOptions({
          appId: data.app[0]?.id,
          homeRedirect: data.app[0]?.app_settings.find(v => v.key === 'home.redirect')?.value,
        })
      })
  }, [host])

  return appOptions.appId ? (
    <Application
      appId={appOptions.appId}
      extraRouteProps={{
        home: {
          path: '/',
          pageName: appOptions.homeRedirect ? <Redirect to={appOptions.homeRedirect} /> : 'HomePage',
          authenticated: false,
        },
      }}
    />
  ) : (
    <LoadingPage />
  )
}

const GET_APP = gql`
  query GET_APP($host: String!) {
    app(where: { app_settings: { key: { _eq: "host" }, value: { _eq: $host } } }, limit: 1) {
      id
      app_settings {
        key
        value
      }
    }
  }
`

export default App
